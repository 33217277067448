import { React, useState } from "react";
import PackageInfo from '../../components/pages/package/packagesInfo';
import ChoosePackages from "../pages/home/ChoosePackages";

function Search() {
  const [inputText, setInputText] = useState("");
 
  console.log(inputText);

  return (
    <>
 <div className="navbar-icons-2">
         <input type="text" placeholder="Search here" onChange={(e) =>setInputText(e.target.value)} />
         </div>
      {/* <ChoosePackages input={inputText} /> */}
    </>
  );
}

export default Search;

