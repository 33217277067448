import React, { Component } from "react";
import about1Img from "../../../assets/images/about-1.png";
import about2Img from "../../../assets/images/about-2.png";
class AboutWrapper extends Component {
   

  render() {
    return (
       <>
           {/* ===============  About wrapper area start =============== */}
           <div className="about-wrapper mt-120">
               <div className="container">
                   <div className="row">
                       <div className="col-lg-7 col-md-12">
                           <div className="about-wrapper-left">
                               <div className="about-img">
                                   <img src={about1Img} alt="" className="img-fluid" />
                               </div>
                               <div className="about-img">
                                   <img src={about2Img} alt="" className="img-fluid" />
                               </div>
                               
                           </div>
                       </div>
                       <div className="col-lg-5 col-md-12">
                           <div className="about-wrapper-right section-head head-left">
                               <h5>About Rahgeer</h5>
                               
                               <p>Rahgeer is a new digital travel company with the intention to provide a seamless and exciting travel experience all around the world. Our commitment in the travel industry is our number one priority for customer satisfaction. Our intention is to connect travellers to memorable experiences, a variety of transportation options 
                                and incredible places to stay – from flights to hotels, car rentals and much more.</p>

                                <br></br>
                                <br></br>
                                <h5>Our Core Values</h5>
                               <p> <b>Basics : </b> simple, comfortable accommodation, local transport and plenty of free time for the independent traveller. <br></br>

                               <b>Original :</b>  a mix of included activities and free time, meals and food freedom, standard and unique accommodation. <br></br>

                                <b>Comfort : </b> more inclusions, more comfortable accommodation and a more relaxed pace.<br></br>

                               <b>Premium : </b> our best accommodation, most comfortable transport, exclusive unique experiences and our most experienced leaders.<br></br>
                                </p>
                           </div>
                       </div>
                   </div>
               </div>
           </div>

           {/* ===============  About wrapper area end =============== */}
       </>
    );
  }
}

export default AboutWrapper;
