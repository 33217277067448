// import package2Img from "../../../assets/images/package/km1.png"
// import package1Img from "../../../assets/images/package/km1.png"
export const PackageInfo = [
    {
        "id":1,
        "package_name":"Kullu Manali",
        "package_price":"10,000",
        "package_stay":"4 Day | 3 Night",
        "rating":"1k+",
        "package_img":require('../../../assets/images/package/km1.png').default,
        // "package_details_link":{},
       
    },
    {
        "id":2,
        "package_name":"Kullu Manali",
        "package_price":"10,000",
        "package_stay":"4 Day | 3 Night",
        "rating":"1k+",
        "package_img":require('../../../assets/images/package/km1.png').default,
        // "package_details_link":{},
       
    },
    {
        "id":3,
        "package_name":"Kullu Manali",
        "package_price":"10,000",
        "package_stay":"4 Day | 3 Night",
        "rating":"1k+",
        "package_img":require('../../../assets/images/package/km1.png').default,
        // "package_details_link":{},
       
    },
    {
        "id":4,
        "package_name":"Kullu Manali",
        "package_price":"10,000",
        "package_stay":"4 Day | 3 Night",
        "rating":"1k+",
        "package_img":require('../../../assets/images/package/km1.png').default,
        // "package_details_link":{},
       
    },
    {
        "id":5,
        "package_name":"Kullu Manali",
        "package_price":"10,000",
        "package_stay":"4 Day | 3 Night",
        "rating":"1k+",
        "package_img":require('../../../assets/images/package/km1.png').default,
        // "package_details_link":{},
       
    },
    {
        "id":6,
        "package_name":"Kullu Manali",
        "package_price":"30,000",
        "package_stay":"4 Day | 3 Night",
        "rating":"1k+",
        "package_img":require('../../../assets/images/package/km1.png').default,
        // "package_details_link":{},
       
    },
    {
        "id":7,
        "package_name":"Kullu Manali",
        "package_price":"20,000",
        "package_stay":"4 Day | 3 Night",
        "rating":"1k+",
        "package_img":require('../../../assets/images/package/km1.png').default,
        // "package_details_link":{},
       
    },
    
]