import React, { Component } from "react";


class LeaveCooment extends Component {
    render() {


    return (
<div className="p-review-input">
                                <form>
                                  <h5>Leave Your Comment</h5>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <input
                                        type="text"
                                        placeholder="Your Full Name"
                                      />
                                    </div>
                                    <div className="col-lg-6">
                                      <input
                                        type="text"
                                        placeholder="Your Email"
                                      />
                                    </div>
                                    <div className="col-lg-12">
                                      <input
                                        type="text"
                                        placeholder="Tour Type"
                                      />
                                    </div>
                                    <div className="col-lg-12">
                                      <textarea
                                        cols={30}
                                        rows={7}
                                        placeholder="Write Message"
                                        defaultValue={""}
                                      />
                                    </div>
                                    <div className="col-lg-12">
                                      <ul className="input-rating">
                                        <li>
                                          <i className="bx bx-star" />
                                        </li>
                                        <li>
                                          <i className="bx bx-star" />
                                        </li>
                                        <li>
                                          <i className="bx bx-star" />
                                        </li>
                                        <li>
                                          <i className="bx bx-star" />
                                        </li>
                                        <li>
                                          <i className="bx bx-star" />
                                        </li>
                                      </ul>
                                      <input
                                        type="submit"
                                        defaultValue="Submit Now"
                                      />
                                    </div>
                                  </div>
                                </form>
                              </div>
    );
  }
}

export default LeaveCooment;
