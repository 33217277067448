import React, { Component } from "react";
import { Link } from "react-router-dom";

import PackageSideBar from "./Package_Components/PackageSideBar";

import km1 from "../../../assets/images/package/km1.png";
import pr_1 from "../../../assets/images/package/pr-1.png";

import gallery1Img from "../../../assets/images/gallary/gl-1.png";



import "react-datepicker/dist/react-datepicker.css";
import LeaveCooment from "./Package_Components/packageComment";

class PackageDetails extends Component {
  
  componentDidMount() {
    this.scrollTop();
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {

    return (
      <>
        {/* ===============  breadcrumb area start =============== */}
        <div className="breadcrumb-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="breadcrumb-wrap">
                  <h2>Tour Package Details</h2>
                  <ul className="breadcrumb-links">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                      <i className="bx bx-chevron-right" />
                    </li>
                    <li>Package Details</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  breadcrumb area end =============== */}
        <div className="package-details-wrapper pt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="package-details">
                  <div className="package-thumb">
                    <img src={km1} alt="" />
                  </div>
                  <div className="package-header">
                    <div className="package-title">
                      <h3> Himachal Pradesh</h3>
                      <strong>
                        <i className="flaticon-arrival" />
                        Kullu-Manali
                      </strong>
                    </div>
                    <div className="pd-review">
                      <p>Excellent</p>
                      <ul>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bx-star" />
                        </li>
                      </ul>
                      <p>8 Review</p>
                    </div>
                  </div>
                  <div className="p-short-info">
                    <div className="single-info">
                      <i className="flaticon-clock" />
                      <div className="info-texts">
                        <strong>Duration</strong>
                        <p>4Day|3Night</p>
                      </div>
                    </div>
                    <div className="single-info">
                      <i className="flaticon-footprints" />
                      <div className="info-texts">
                        <strong>Tour Type</strong>
                        <p>Daily Tour</p>
                      </div>
                    </div>
                    <div className="single-info">
                      <i className="flaticon-traveller" />
                      <div className="info-texts">
                        <strong>Group Size</strong>
                        <p>Unlimited</p>
                      </div>
                    </div>
                    <div className="single-info">
                      <i className="flaticon-translate" />
                      <div className="info-texts">
                        <strong>Languages</strong>
                        <p>English/Hindi</p>
                      </div>
                    </div>
                  </div>
                  <div className="package-tab">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          <i className="flaticon-info" />
                          Information
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          <i className="flaticon-clipboard" />
                          Travel Plan
                        </button>
                      </li>
                      {/* <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-contact-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-contact"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                        >
                          {" "}
                          <i className="flaticon-gallery" />
                          Our Gallary
                        </button>
                      </li> */}
                    </ul>
                    <div
                      className="tab-content p-tab-content"
                      id="pills-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="tab-content-1">
                              <div className="p-overview">
                                <h5>Overview</h5>
                                <p>
                                  Manali is one of the best hill stations
                                  situated in Indian state of Himachal Pradesh
                                  and Mall Road is the main street of Manali,
                                  which is worth visiting. There are numerous
                                  shops, which are situated on both sides of the
                                  Mall road and it is open for vehicles as well.
                                  Opposite to the Mall Road, there is a big
                                  parking lot where tourists can park their
                                  vehicles and enjoy shopping. Being a main
                                  market, there is always a lot of hustle and
                                  bustle on Mall Road and it remains full of
                                  visitors and shoppers. There are a number of
                                  showrooms, department stores, shops,
                                  restaurants, hotels and cafés on this road.
                                  The Mall road offers handicraft products, like
                                  pottery and gift items made from wood, jewelry
                                  and lots of other things which tourists can
                                  buy from this market. Manali is very famous
                                  for Kullu and Kinnauri shawls which are easily
                                  available in the shops on Mall road.
                                </p>
                              </div>
                              <div className="p-highlight">
                                <h5>Highlight</h5>
                                <ul>
                                  <li>
                                    <i className="bx bx-circle" />{" "}
                                    <p>
                                      Your tour package cost with Super Deluxe
                                      hotel (MAP) basis Super deluxe Room.
                                    </p>
                                  </li>
                                  <li>
                                    <i className="bx bx-circle" />{" "}
                                    <p>Hotel Snow Heaven Resort Manali.</p>
                                  </li>
                                  <li>
                                    <i className="bx bx-circle" />{" "}
                                    <p>Enjoy the local sightseeing places :</p>
                                  </li>
                                  <li>
                                    <i className="bx bx-circle" />{" "}
                                    <p>
                                      1) Hadimba Devi Temple. 2) Club House. 3)
                                      Vashisth Hot water Spring & temple.{" "}
                                    </p>
                                  </li>
                                  <li>
                                    <i className="bx bx-circle" />{" "}
                                    <p>
                                      4) Tibetan Monastery. 5) Van Vihar. 6)
                                      Mall Road.
                                    </p>
                                  </li>
                                </ul>
                              </div>
                              <div className="p-details-table">
                                <table className="table caption-top">
                                  <tbody>
                                    <tr>
                                      <td>Destination</td>
                                      <td>Manali</td>
                                    </tr>
                                    <tr>
                                      <td>Depature</td>
                                      <td>Yes Required</td>
                                    </tr>
                                    {/* <tr>
                                                                    <td>Departure Time</td>
                                                                    <td>01 April, 2021   10.00AM</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Return Time</td>
                                                                    <td>08 April, 2021   10.00AM</td>
                                                                </tr> */}
                                    <tr>
                                      <td>Included</td>
                                      <td>
                                        <ul className="table-list-allow">
                                          <li>
                                            <i className="bx bx-check" />
                                            Package for 1 super deluxe room room
                                            with extra bed for 3 Night 4 Days{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" />{" "}
                                            Welcome drink on arrival time.
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" /> Hotels
                                            on 03 breakfast and 03 Dinner basis.
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" /> All
                                            sights seeing and transfer for
                                            Individual cab mentioned places not
                                            any sharing basis
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" /> 04
                                            tickets Volvo bus from Delhi to
                                            Manali .
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" /> 04
                                            tickets VOLVO FROM Manali to Delhi .
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" /> No any
                                            hidden charges.
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" /> Rates
                                            are inclusive of all taxes.
                                          </li>
                                          <li>
                                            <i className="bx bx-check" /> All
                                            parking fees ,fuel expenses and toll
                                            taxes are in our scope
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Excluded</td>
                                      <td>
                                        <ul className="table-list-disallow">
                                          <li>
                                            {" "}
                                            <i className="bx bx-x" /> Monument
                                            entrance fee/Camera fees.{" "}
                                          </li>
                                          <li>
                                            <i className="bx bx-x" /> Any
                                            adventure activity.{" "}
                                          </li>
                                          <li>
                                            <i className="bx bx-x" /> Meal other
                                            than specified.{" "}
                                          </li>
                                          <li>
                                            <i className="bx bx-x" /> For Heater
                                            Requirement directly contact to
                                            Hotelier
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={2} className="table-bottom">
                                        <i className="flaticon-public-transport" />
                                        Travel With Bus
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="p-rationg">
                                <h5>Rating</h5>
                                <div className="rating-card">
                                  <div className="r-card-avarag">
                                    <h2>4.9</h2>
                                    <h5>Excellent</h5>
                                  </div>
                                  <div className="r-card-info">
                                    <ul>
                                      <li>
                                        <strong>Accommodation</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Transport</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Comfort</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Hospitality</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Food</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="p-review">
                                <ul>
                                  <li className="p-review-card">
                                    <div className="p-review-info">
                                      <div className="p-reviewr-img">
                                        <img src={pr_1} alt="" />
                                      </div>
                                      <div className="p-reviewer-info">
                                        <strong>Bertram Bil</strong>
                                        <p>2 April, 2021 10.00PM</p>
                                        <ul className="review-star">
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="p-review-texts ">
                                      <p>
                                        I am a little late in posting a review.
                                        We returned from this trip in early
                                        March, right when everything started to
                                        close down due to Covid-19. I can tell
                                        you that the memories of this trip have
                                        kept me going while sheltering in place.
                                        If you are thinking this might be the
                                        trip for you, please know that it is
                                        very fast paced with moving from place
                                        to place almost every night was quite
                                        challenging. The cycling itself was
                                        challenging based on ones fitness level.
                                        My husband completed every kilometer,
                                        while I did about three-quarters of the
                                        cycling. A lot of it depends on the
                                        heat, but for a female in my early 50s,
                                        I found it very doable. We had the most
                                        fabulous guide and crew. I cannot say
                                        enough of about our guide Son - he was
                                        absolutely amazing - taking care of all
                                        the details while we enjoyed ourselves.
                                        There are plenty of water/snack stops
                                        with fresh fruit and amazing snacks
                                        (ginger/nuts/cookies). They take very
                                        good care of you.
                                      </p>
                                    </div>
                                    <Link to={`#`} className="r-reply-btn">
                                      <i className="bx bx-reply" /> Reply
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <LeaveCooment />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <div className="tab-content-2">
                          <div className="row">
                            <div className="col-lg-12">
                              {/* <div className="p-timeline-overview">
                                                            <h5>Overview</h5>
                                                            <p>Pellentesque accumsan magna in augue sagittis, non fringilla eros molestie. Sed feugiat mi nec ex vehicula, nec vestibulum orci semper. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec tristique commodo fringilla. Duis aliquet varius mauris eget rutrum. Nullam sit amet justo consequat, bibendum orci in, convallis enim. Proin convallis neque viverra finibus cursus. Mauris lacinia lacinia erat in finibus.</p>
                                                        </div> */}
                              <ul className="p-timeline">
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>01</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      Day 1 : Delhi-Manali (570kms, 12-14 Hrs)
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                      Take evening Volvo coach from Delhi,
                                      Departure 14:00Hrs, overnight on the
                                      board..
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>02</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      DAY 2 : Manali Local Town Sightseeing(half
                                      day 4:00 hours)
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                      Meal: Welcome Drink and Dinner.<br></br>
                                      After reaching Manali get ready to enjoy
                                      the local sight seeing places :<br></br>
                                      1) Hadimba Devi Temple:<br></br>
                                      2) Club House:<br></br>
                                      3) Vashisth Hot water Spring & temple:
                                      <br></br>
                                      4) Tibetan Monastery:<br></br>
                                      5) Van Vihar<br></br>
                                      6) Mall Road<br></br>
                                      Evening free for shopping on The Mall
                                      Road. Dinner and overnight stay at Hotel.
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>03</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      DAY 3 : Rohtang Pass/up to Snow
                                      point/Solang Valley
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                      Meal: Breakfast and Dinner.<br></br>
                                      After breakfast visit to Rohtang Pass/Snow
                                      point (Situated about 51km from Manali
                                      town at an altitude of 4,111meters (13,400
                                      ft) on the highway to keylong, is Rohtang
                                      Pass. Here one sees the majesty of the
                                      mountains at its height and splendor. At
                                      the top of the pass the air seems to
                                      glitter against the snow as you look down
                                      over herringboned ridges into the Lahaul
                                      Valley. There is a beautiful Doshohar Lake
                                      left to the pass, where pilgrims go every
                                      year on the auspicious day of 20th
                                      Bhadon),enroute visit Kothi Gorge, Gulaba,
                                      Rahala Falls, Marhi and Solang Valley and,
                                      Enjoy skiing, paragliding and other
                                      adventurous activities (Cost not
                                      included), Return to hotel by evening.
                                      Dinner and overnight stay at Hotel. Note:
                                      - Rohtang Pass Sightseeing will be
                                      provided as per Govt. or Administration
                                      notification, Rohtang pass might be closed
                                      due to heavy snow falls or scheduled
                                      maintenance. (Rohtang pass sightseeing
                                      depends upon permissions). Up to snow
                                      point.
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>04</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>DAY 4 : Kullu Valley-Kasol</h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                      Meal: Breakfast and Dinner.<br></br>
                                      After breakfast, prepare for whole day
                                      sightseeing tour to kullu valley, which
                                      includes-Vaishno Devi Temple, Kullu Shawl
                                      industry,Parvati Valley,Kasol .O/N/S at
                                      Manali hotel.
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>05</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>Day 5 : Kasol to Delhi</h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                      Meal: Breakfast.<br></br>
                                      After Breakfast take your cab and visit
                                      for local excursion of Manikaran Valley,
                                      Bid adieu to Kasol with lifetime memories
                                      and take evening Volvo coach ,Overnight on
                                      the board.
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        {/* <div className="tab-contant-3">
                        <div class="row">
                            <div class="col-sm-12 col-md-6 mb-3">
                            <img src={gallery1Img} alt="" class="fluid img-thumbnail" />      
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3">
                              <img src={gallery1Img} alt="" class="fluid img-thumbnail" />      
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3">
                              <img src={gallery1Img} alt="" class="fluid img-thumbnail" />      
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3">
                              <img src={gallery1Img} alt="" class="fluid img-thumbnail" />      
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3">
                              <img src={gallery1Img} alt="" class="fluid img-thumbnail" />      
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3">
                              <img src={gallery1Img} alt="" class="fluid img-thumbnail" />      
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                    <PackageSideBar />              
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PackageDetails;
