import React, { Component } from "react";
import { Link } from "react-router-dom";

import pd_thumb from "../../../assets/images/package/coorg.jpg";
import pr_1 from "../../../assets/images/package/pr-1.png";

import gallery1Img from "../../../assets/images/package/kashmir/k1.jpg";

import "react-datepicker/dist/react-datepicker.css";
import PackageSideBar from "./Package_Components/PackageSideBar";
import LeaveCooment from "./Package_Components/packageComment";
import { SRLWrapper } from "simple-react-lightbox";

class PackageDetails extends Component {

  componentDidMount() {
    this.scrollTop();
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {

    return (
      <>
        {/* ===============  breadcrumb area start =============== */}
        <div className="breadcrumb-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="breadcrumb-wrap">
                  <h2>Tour Package Details</h2>
                  <ul className="breadcrumb-links">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                      <i className="bx bx-chevron-right" />
                    </li>
                    <li>Package Details</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  breadcrumb area end =============== */}
        <div className="package-details-wrapper pt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="package-details">
                  <div className="package-thumb">
                    <img src={pd_thumb} alt="" />
                  </div>
                  <div className="package-header">
                    <div className="package-title">
                      <h3> Karnataka</h3>
                      <strong>
                        <i className="flaticon-arrival" />
                       Bangalore Coorg
                      </strong>
                    </div>
                    <div className="pd-review">
                      <p>Excellent</p>
                      <ul>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bx-star" />
                        </li>
                      </ul>
                      <p>8 Review</p>
                    </div>
                  </div>
                  <div className="p-short-info">
                    <div className="single-info">
                      <i className="flaticon-clock" />
                      <div className="info-texts">
                        <strong>Duration</strong>
                        <p>3 Day| 2 Night</p>
                      </div>
                    </div>
                    <div className="single-info">
                      <i className="flaticon-footprints" />
                      <div className="info-texts">
                        <strong>Tour Type</strong>
                        <p>Daily Tour</p>
                      </div>
                    </div>
                    <div className="single-info">
                      <i className="flaticon-traveller" />
                      <div className="info-texts">
                        <strong>Group Size</strong>
                        <p>Unlimited</p>
                      </div>
                    </div>
                    <div className="single-info">
                      <i className="flaticon-translate" />
                      <div className="info-texts">
                        <strong>Languages</strong>
                        <p>English/Hindi</p>
                      </div>
                    </div>
                  </div>
                  <div className="package-tab">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          <i className="flaticon-info" />
                          Information
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          <i className="flaticon-clipboard" />
                          Travel Plan
                        </button>
                      </li>
                      {/* <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-contact-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-contact"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                        >
                          {" "}
                          <i className="flaticon-gallery" />
                          Our Gallary
                        </button>
                      </li> */}
                    </ul>
                    <div
                      className="tab-content p-tab-content"
                      id="pills-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="tab-content-1">
                              <div className="p-overview">
                                <h5>Overview</h5>
                                <p>
                                The most prosperous hill station in Karnataka is Coorg, 
                                often known as Kodagu.
                                It is renowned for its rich foliage and breath-takingly diverse beauty. 
                                </p>
                              </div>
                              <div className="p-highlight">
                                <h5>Highlights</h5>
                                <ul>
                                <li>
                                    <i className="bx bx-circle" />{" "}
                                    <p><b>Pick up and Drop: </b> Bangalore</p>
                                  </li>
                                  <li>
                                    <i className="bx bx-circle" />{" "}
                                    
                                    <p>
                                    <b>Hotel: </b> Hotel Minister’s Kourt / Hotel Vasathi/ Similar at Coorg
                                    </p>
                                  </li>
                                  <li>
                                    <i className="bx bx-circle" />{" "}
                                    
                                    <p>
                                    <b>Price: </b> 
                                    </p>
                                  </li>
                                  <table className="table  p-price">
                                  <tbody>
                                    <tr>
                                      <td>Number of Pax Travelling together</td>
                                      <td>2 Pax</td>
                                      <td>4 Pax</td>
                                      <td>6 Pax</td>
                                    </tr>
                                    <tr>
                                        <td>Price Per Person</td>
                                        <td>11495</td>
                                        <td>8195</td>
                                        <td>7655</td>
                                    </tr>
                                    
                                    
                                    </tbody>
                                </table>
                                <li>
                                    <i className="bx bx-circle" />{" "}
                                    <p>
                                    <b>Note: </b> Price not Valid from 20th Dec to 10th Jan
                                    </p>
                                  </li>
                                </ul>
                             
                              </div>
                              <div className="p-details-table">
                                <table className="table caption-top">
                                  <tbody>
                                    <tr>
                                      <td>Destination</td>
                                      <td>Coorg</td>
                                    </tr>
                                    <tr>
                                      <td>Depature</td>
                                      <td>Yes Required</td>
                                    </tr>

                                    <tr>
                                      <td>Included</td>
                                      <td>
                                        <ul className="table-list-allow">
                                          <li>
                                            <i className="bx bx-check" /> 
                                            Accommodation{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" /> 
                                            Breakfast
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" /> 
                                            Travel (total of 900 kms). Extra KMS will be charged @ Rs.10/KM<br></br>
                                           	
                                            •  Sedan Vehicle like Swift Dzire / Itios upto 4 people<br></br>
                                               •  Innova / Similar for 5-7 pax

                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" />Fuel Charges and Driver Bata
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" />All hotel taxes (GST Extra)
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Excluded</td>
                                      <td>
                                        <ul className="table-list-disallow">
                                          <li>
                                            {" "}
                                            <i className="bx bx-x" />
                                            Night Driving Charges{" "}
                                          </li>
                                          <li>
                                            <i className="bx bx-x" /> Lunch and Dinner{" "}
                                          </li>
                                          <li>
                                            <i className="bx bx-x" /> Toll and Parking Charges{" "}
                                          </li>
                                          <li>
                                            <i className="bx bx-x" /> Anything
                                            that is not mentioned in the
                                            Inclusions.
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              
                              <div className="p-rationg">
                                <h5>Rating</h5>
                                <div className="rating-card">
                                  <div className="r-card-avarag">
                                    <h2>4.9</h2>
                                    <h5>Excellent</h5>
                                  </div>
                                  <div className="r-card-info">
                                    <ul>
                                      <li>
                                        <strong>Accommodation</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Transport</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Comfort</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Hospitality</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Food</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="p-review">
                                <ul>
                                  <li className="p-review-card">
                                    <div className="p-review-info">
                                      <div className="p-reviewr-img">
                                        <img src={pr_1} alt="" />
                                      </div>
                                      <div className="p-reviewer-info">
                                        <strong>Bertram Bil</strong>
                                        <p>2 April, 2021 10.00PM</p>
                                        <ul className="review-star">
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="p-review-texts ">
                                      <p>
                                      Good
                                      </p>
                                    </div>
                                    <Link to={`#`} className="r-reply-btn">
                                      <i className="bx bx-reply" /> Reply
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                             <LeaveCooment />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <div className="tab-content-2">
                          <div className="row">
                            <div className="col-lg-12">
                              {/* <div className="p-timeline-overview">
                                                            <h5>Overview</h5>
                                                            <p>Pellentesque accumsan magna in augue sagittis, non fringilla eros molestie. Sed feugiat mi nec ex vehicula, nec vestibulum orci semper. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec tristique commodo fringilla. Duis aliquet varius mauris eget rutrum. Nullam sit amet justo consequat, bibendum orci in, convallis enim. Proin convallis neque viverra finibus cursus. Mauris lacinia lacinia erat in finibus.</p>
                                                        </div> */}
                              <ul className="p-timeline">
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>01</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      Day 1 : Bangalore to Coorg. On the way sightseeing
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                    On the way to Coorg via Mysore , you can visit Dubare elephant camp, Nisargadhama (Natural formed island in river Cauvery. There is a boating,
                                    Elephant ride, Deer park and natural Bamboo Plantation) and Golden
                                    temple: world’s biggest Buddhist’s Monastery and second largest Tibetans settlement in India after Dharamshala. Overnight stay at Coorg

                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>02</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      DAY 2 : Coorg Local Sightseeing
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                    On this day visit abbey falls , Tala Kaveri, Raja Seat and Omkareshwar Temple.
                                     If time permits you can also visit Mandalpatti ( where you have to go by the local Jeep as car cannot travel in that terrain).
                                     Return to hotel and overnight stay in Coorg
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>03</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      DAY 3 : Visit Nagarahole and Drop at Bangalore
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                    After breakfast, check out and start your journey to Nagarahole. After visiting Nagarahole wild life sanctuary,
                                     start your journey to Bangalore and drop at Bangalore.
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>04</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      DAY 4 : Departure
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                    After breakfast check out from the hotel and 
                                    drop to the airport/ Railway station for your onward journey with sweet memories of the Goa trip.
                                    </p>
                                  </div>
                                </li>
                                
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                         {/* <div className="tab-contant-3">
                          <SRLWrapper>
                            <div className="row">
                              
                              
                              <div class="col-sm-12 col-md-6 mb-3">
                                    <Link
                                      to={gallery1Img}
                                      className=""
                                    >
                                      <img
                                        src={gallery1Img}
                                        alt="gallary-img"
                                      />
                                    </Link>
                                </div>
                                <div class="col-sm-12 col-md-6 mb-3">
                                    <Link
                                      to={gallery1Img}
                                      className=""
                                    >
                                      <img
                                        src={gallery1Img}
                                        alt="gallary-img"
                                      />
                                    </Link>
                                </div>
                                <div class="col-sm-12 col-md-6 mb-3">
                                    <Link
                                      to={gallery1Img}
                                      className=""
                                    >
                                      <img
                                        src={gallery1Img}
                                        alt="gallary-img"
                                      />
                                    </Link>
                                </div>
                                    <div class="col-sm-12 col-md-6 mb-3">
                                    <Link
                                      to={gallery1Img}
                                      className=""
                                    >
                                      <img
                                        src={gallery1Img}
                                        alt="gallary-img"
                                      />
                                    </Link>
                                   </div>
                
                                  
                                    
            
                            </div>
                          </SRLWrapper>
                        </div> */}
                    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
               <PackageSideBar />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PackageDetails;
