import React, { Component } from "react";
import {Link} from "react-router-dom";

import pm_sm_1 from "../../../../assets/images/package/p-sm-1.png";
import pm_sm_4 from "../../../../assets/images/package/p-sm-4.png";
import pm_sm_2 from "../../../../assets/images/package/p-sm-2.png";
import pm_sm_3 from "../../../../assets/images/package/p-sm-3.png";


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
class PackageSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
    };
  }
  changeDatepickerHandeller = (date) => {
    this.setState({ startDate: date });
  };

  componentDidMount() {
    this.scrollTop();
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    const startDate = this.state.startDate;

    return (
        <div>
                <div className="package-d-sidebar">
                  <div className="row">
                    <div className="col-lg-12 col-md-6">
                      <div className="p-sidebar-form">
                        <form>
                          <h5 className="package-d-head">Book This Package</h5>
                          <div className="row">
                            <div className="col-lg-12">
                              <input type="text" placeholder="Your Full Name" />
                            </div>
                            <div className="col-lg-12">
                              <input type="email" placeholder="Your Email" />
                            </div>
                            <div className="col-lg-12">
                              <input type="tel" placeholder="Phone" />
                            </div>

                            <div className="col-lg-6">
                              <select
                                className="form-select"
                                aria-label="Default select example"
                              >
                                <option selected>Adult</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                              </select>
                            </div>
                            <div className="col-lg-6">
                              <select
                                className="form-select"
                                aria-label="Default select example"
                              >
                                <option selected>Child</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                              </select>
                            </div>
                            <div className="col-lg-12">
                              <div
                                className="calendar-input"
                                id="packageCalenderMainDiv"
                              >
                                <DatePicker
                                  selected={startDate}
                                  onChange={(date) =>
                                    this.changeDatepickerHandeller(date)
                                  }
                                  className="input-field check-in"
                                  placeholder="dd-mm-yy"
                                />
                                <i
                                  className="flaticon-calendar"
                                  id="packageCalenderIcon"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <textarea
                                cols={30}
                                rows={7}
                                placeholder="Message"
                                defaultValue={""}
                              />
                            </div>
                            <div className="col-lg-12">
                              <input type="submit" defaultValue="Book Now" />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6">
                      <div className="p-sidebar-cards mt-40">
                        <h5 className="package-d-head">Popular Packages</h5>
                        <ul className="package-cards">
                          <li className="package-card-sm">
                            <div className="p-sm-img">
                              <img src={pm_sm_1} alt="" />
                            </div>
                            <div className="package-info">
                              <div className="package-date-sm">
                                <strong>
                                  <i className="flaticon-calendar" />4 Days/3
                                  night
                                </strong>
                              </div>
                              <h3>
                                <i className="flaticon-arrival" />
                                <Link
                                  to={`${process.env.PUBLIC_URL}/package-details`}
                                >
                                  Kullu Manali
                                </Link>
                              </h3>
                              <h5>
                                <span>8200</span>/ Per Person
                              </h5>
                            </div>
                          </li>
                          <li className="package-card-sm">
                            <div className="p-sm-img">
                              <img src={pm_sm_4} alt="" />
                            </div>
                            <div className="package-info">
                              <div className="package-date-sm">
                                <strong>
                                  <i className="flaticon-calendar" />4 Days/3
                                  night
                                </strong>
                              </div>
                              <h3>
                                <i className="flaticon-arrival" />
                                <Link
                                  to={`${process.env.PUBLIC_URL}/goa`}
                                >
                                  Goa
                                </Link>
                              </h3>
                              <h5>
                                <span>6000</span>/ Per Person
                              </h5>
                            </div>
                          </li>
                          <li className="package-card-sm">
                            <div className="p-sm-img">
                              <img src={pm_sm_2} alt="" />
                            </div>
                            <div className="package-info">
                              <div className="package-date-sm">
                                <strong>
                                  <i className="flaticon-calendar" />4 Days/3
                                  night
                                </strong>
                              </div>
                              <h3>
                                <i className="flaticon-arrival" />
                                <Link
                                  to={`${process.env.PUBLIC_URL}/Ooty`}
                                >
                                  Mysore Ooty Coonor
                                </Link>
                              </h3>
                              <h5>
                                <span>8500</span>/ Per Person
                              </h5>
                            </div>
                          </li>
                          <li className="package-card-sm">
                            <div className="p-sm-img">
                              <img src={pm_sm_3} alt="" />
                            </div>
                            <div className="package-info">
                              <div className="package-date-sm">
                                <strong>
                                  <i className="flaticon-calendar" />3 Days/2
                                  night
                                </strong>
                              </div>
                              <h3>
                                <i className="flaticon-arrival" />
                                <Link
                                  to={`${process.env.PUBLIC_URL}/coorg`}
                                >
                                 Coorg
                                </Link>
                              </h3>
                              <h5>
                                <span>6000</span>/ Per Person
                              </h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6">
                      <div className="p-sidebar-organizer mt-20 p-highlight">
                        <div className="d-none d-sm-block widget-box st-information-contact st-border-radius">
                          <h3 className="bold">Information Contact</h3>
                          <div>
                            <h5>Email </h5>
                            <p>admin@rahgeer.in </p>
                            <h5>Website </h5>
                            <p>https://rahgeer.in/ </p>
                            <h5>Phone </h5>
                            <p>+19983782671 </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-12 col-md-6">
                      <div className="p-sidebar-banner mt-40">
                        <img src={sidebarBannar} alt="" className="img-fluid" />
                        <div className="sidebar-banner-overlay">
                          <div className="overlay-content">
                            <h3>Get 50% Off In Dubai Tour</h3>
                            <div className="sidebar-banner-btn">
                              <Link to={`#`}>Book Now</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
        </div>
    );
  }
}

export default PackageSideBar;
