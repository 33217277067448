import React, { Component } from "react";
import { Link } from "react-router-dom";
import ChoosePackage from "../home/ChoosePackages"

class Packages extends Component {
  render() {
    return (
        <div>
            {/* ===============  breadcrumb area start =============== */}
            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="breadcrumb-wrap">
                                <h2>Tour Package</h2>
                                <ul className="breadcrumb-links">
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                                        <i className="bx bx-chevron-right" />
                                    </li>
                                    <li>Tour Package</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ===============  breadcrumb area end =============== */}
            

            {/* ===============  Package  area start =============== */}
           <ChoosePackage />
            {/* ===============  Package  area end =============== */}
        </div>
    );
  }
}

export default Packages;
