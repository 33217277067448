import React, { Component } from "react";
import { Link } from "react-router-dom";

import pd_thumb from "../../../assets/images/package/kashmir/k1.jpg";
import pr_1 from "../../../assets/images/package/pr-1.png";

import gallery1Img from "../../../assets/images/package/kashmir/k1.jpg";



import PackageSideBar from "./Package_Components/PackageSideBar";
import LeaveCooment from "./Package_Components/packageComment";

class PackageDetails extends Component {


  componentDidMount() {
    this.scrollTop();
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {

    return (
      <>
        {/* ===============  breadcrumb area start =============== */}
        <div className="breadcrumb-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="breadcrumb-wrap">
                  <h2>Tour Package Details</h2>
                  <ul className="breadcrumb-links">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                      <i className="bx bx-chevron-right" />
                    </li>
                    <li>Package Details</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  breadcrumb area end =============== */}
        <div className="package-details-wrapper pt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="package-details">
                  <div className="package-thumb">
                    <img src={pd_thumb} alt="" />
                  </div>
                  <div className="package-header">
                    <div className="package-title">
                      <h3> Kashmir</h3>
                      <strong>
                        <i className="flaticon-arrival" />
                        SRINAGAR SONAMARG GULMARG PAHALGAM
                      </strong>
                    </div>
                    <div className="pd-review">
                      <p>Excellent</p>
                      <ul>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bx-star" />
                        </li>
                      </ul>
                      <p>8 Review</p>
                    </div>
                  </div>
                  <div className="p-short-info">
                    <div className="single-info">
                      <i className="flaticon-clock" />
                      <div className="info-texts">
                        <strong>Duration</strong>
                        <p>6Day|5Night</p>
                      </div>
                    </div>
                    <div className="single-info">
                      <i className="flaticon-footprints" />
                      <div className="info-texts">
                        <strong>Tour Type</strong>
                        <p>Daily Tour</p>
                      </div>
                    </div>
                    <div className="single-info">
                      <i className="flaticon-traveller" />
                      <div className="info-texts">
                        <strong>Group Size</strong>
                        <p>Unlimited</p>
                      </div>
                    </div>
                    <div className="single-info">
                      <i className="flaticon-translate" />
                      <div className="info-texts">
                        <strong>Languages</strong>
                        <p>English/Hindi</p>
                      </div>
                    </div>
                  </div>
                  <div className="package-tab">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          <i className="flaticon-info" />
                          Information
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          <i className="flaticon-clipboard" />
                          Travel Plan
                        </button>
                      </li>
                      {/* <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-contact-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-contact"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                        >
                          {" "}
                          <i className="flaticon-gallery" />
                          Our Gallary
                        </button>
                      </li> */}
                    </ul>
                    <div
                      className="tab-content p-tab-content"
                      id="pills-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="tab-content-1">
                              <div className="p-overview">
                                <h5>Overview</h5>
                                <p>
                                  If there is heaven on the earth, it is Kashmir
                                  only. Embellished with oodles of natural
                                  marvels, Kashmir is a land of beauty and
                                  elegance. Renowned for its natural
                                  exquisiteness, Kashmir is among the best
                                  retreats for travellers. It soothes their eyes
                                  with the delighting vistas of the Dal Lake,
                                  Mughal Gardens, meadow of flowers and snowy
                                  landscape. It is difficult to describe the
                                  beauty of this northernmost state in words.
                                  One can only feel it and fall in love with it.
                                </p>
                              </div>
                              <div className="p-details-table">
                                <table className="table caption-top">
                                  <tbody>
                                    <tr>
                                      <td>Destination</td>
                                      <td>Srinagar</td>
                                    </tr>
                                    <tr>
                                      <td>Depature</td>
                                      <td>Yes Required</td>
                                    </tr>

                                    <tr>
                                      <td>Included</td>
                                      <td>
                                        <ul className="table-list-allow">
                                          <li>
                                            <i className="bx bx-check" />
                                            3Nstay in Hotel Royal Arabia or
                                            similar at Srinagar{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" /> 1Nstay
                                            in Hotel Srichan Resort or similar
                                            at Pahalgam
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" /> 1Nstay
                                            in Super Deluxe Houseboat at Dal
                                            Lake
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" /> Daily
                                            Breakfast & Dinner (MAP)
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" />{" "}
                                            Srinagar sightseeing: Mughal Gardens
                                            and Shankaracharya Temple.
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" /> Full
                                            day excursion to Sonamarg
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" />{" "}
                                            Pahalgam Sightseeing: Betaab Valley,
                                            Chandanwari and Aru Valley
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" />1 Hour
                                            ShikaraRide in Dal Lake
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" />
                                            Pick Up & Drop from Srinagar Airport
                                          </li>
                                          <li>
                                            <i className="bx bx-check" /> All
                                            Transfers & Sightseeing by Non A/C
                                            Sedan
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Excluded</td>
                                      <td>
                                        <ul className="table-list-disallow">
                                          <li>
                                            {" "}
                                            <i className="bx bx-x" />
                                            5% GST.{" "}
                                          </li>
                                          <li>
                                            <i className="bx bx-x" /> Entrance
                                            fees at the monuments.{" "}
                                          </li>
                                          <li>
                                            <i className="bx bx-x" /> Meal other
                                            than specified.{" "}
                                          </li>
                                          <li>
                                            <i className="bx bx-x" /> Child
                                            below 4 Years Complimentary.
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={2} className="table-bottom">
                                        <i className="flaticon-public-transport" />
                                        Travel With Bus
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="p-rationg">
                                <h5>Rating</h5>
                                <div className="rating-card">
                                  <div className="r-card-avarag">
                                    <h2>4.9</h2>
                                    <h5>Excellent</h5>
                                  </div>
                                  <div className="r-card-info">
                                    <ul>
                                      <li>
                                        <strong>Accommodation</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Transport</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Comfort</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Hospitality</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Food</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="p-review">
                                <ul>
                                  <li className="p-review-card">
                                    <div className="p-review-info">
                                      <div className="p-reviewr-img">
                                        <img src={pr_1} alt="" />
                                      </div>
                                      <div className="p-reviewer-info">
                                        <strong>Bertram Bil</strong>
                                        <p>2 April, 2021 10.00PM</p>
                                        <ul className="review-star">
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="p-review-texts ">
                                      <p>
                                        I am a little late in posting a review.
                                        We returned from this trip in early
                                        March, right when everything started to
                                        close down due to Covid-19. I can tell
                                        you that the memories of this trip have
                                        kept me going while sheltering in place.
                                        If you are thinking this might be the
                                        trip for you, please know that it is
                                        very fast paced with moving from place
                                        to place almost every night was quite
                                        challenging. The cycling itself was
                                        challenging based on ones fitness level.
                                        My husband completed every kilometer,
                                        while I did about three-quarters of the
                                        cycling. A lot of it depends on the
                                        heat, but for a female in my early 50s,
                                        I found it very doable. We had the most
                                        fabulous guide and crew. I cannot say
                                        enough of about our guide Son - he was
                                        absolutely amazing - taking care of all
                                        the details while we enjoyed ourselves.
                                        There are plenty of water/snack stops
                                        with fresh fruit and amazing snacks
                                        (ginger/nuts/cookies). They take very
                                        good care of you.
                                      </p>
                                    </div>
                                    <Link to={`#`} className="r-reply-btn">
                                      <i className="bx bx-reply" /> Reply
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <LeaveCooment />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <div className="tab-content-2">
                          <div className="row">
                            <div className="col-lg-12">
                              {/* <div className="p-timeline-overview">
                                                            <h5>Overview</h5>
                                                            <p>Pellentesque accumsan magna in augue sagittis, non fringilla eros molestie. Sed feugiat mi nec ex vehicula, nec vestibulum orci semper. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec tristique commodo fringilla. Duis aliquet varius mauris eget rutrum. Nullam sit amet justo consequat, bibendum orci in, convallis enim. Proin convallis neque viverra finibus cursus. Mauris lacinia lacinia erat in finibus.</p>
                                                        </div> */}
                              <ul className="p-timeline">
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>01</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      Day 1 : Arrival Srinagar-Local
                                      Sightseeing(Around 50kms)
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                      On arrival at Srinagar airport, you will
                                      be meeting our representative. Then, enjoy
                                      the drive to Srinagar. Check into the
                                      hotel and relax. Later, proceed for
                                      Shankaracharya Temple Darshan and
                                      sightseeing. Visit the world famous and
                                      stunning Mughal Gardens, Nishat Bagh,
                                      Shalimar Garden, Chashme Shahi Garden, and
                                      Pari Mahal. Retreat to your hotel for
                                      dinner and an overnight stay.
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>02</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      DAY 2 : Srinagar-Sonamarg-Srinagar(90kms
                                      one way)
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                      After Breakfast, get ready for a beautiful
                                      drive to Sonamarg. Sonamarg (2800 Meters
                                      above sea level), means (Meadow of Golden)
                                      has, as its backdrop snowy mountains
                                      against a cerulean sky. Adorned with a
                                      majestic glacier, meadow, dense forests,
                                      and snow-capped mountains makes Sonmarg a
                                      major tourist attraction in Kashmir. For
                                      all those adventure loving people who wish
                                      to enjoy camping at a hill station,
                                      Sonmarg is the ideal destination. Tourists
                                      flock to this small hill resort in heavy
                                      numbers to see the enchanting natural
                                      beauty and snow clad peaks. Known as the
                                      ‘Meadow of Gold’, Sonmarg is set amidst a
                                      valley of flowers and lakes. Ponies can be
                                      hired for a trip up to Thajiwas glacier, a
                                      Dinner (2500 will be the extra cost). In
                                      the evening return back to Srinagar. You
                                      have the rest of the day for recreation.
                                      Enjoy Dinner & get ready for an overnight
                                      stay in the hotel.
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>03</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      DAY 3 : Srinagar-Gulmarg-Srinagar(45kms
                                      one way)
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                      After breakfast, Check-out from Srinagar
                                      Hotel and get ready for a drive to
                                      Gulmarg. Situated at an altitude of 2730
                                      Meters, Gulmarg, means Meadow of Flowers,
                                      is a town, a hill station and a popular
                                      skiing destination during winters. You
                                      must visit Gulmarg to explore the backing
                                      stands of the splendid mature pines
                                      traversing you to the elevation of Bald Mt
                                      Apharwat. Gulmarg is one of the beautiful
                                      place that can be visited in Kashmir
                                      valley. Gulmarg literally means the meadow
                                      of flowers. Originally, Gulmarg was called
                                      Gaurimag, named after Shiva’s coasts.
                                      Gulmarg, the hill resort is located only
                                      52 km. to the south west of Srinagar in
                                      the Baramulla district of Jammu and
                                      Kashmir. Relax and take-in the beauty of
                                      Gulmarg for a memorable experience. Later,
                                      return back to Srinagar to your hotel.
                                      Have dinner and an overnight stay at the
                                      hotel.
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>04</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      DAY 4 : Srinagar-Pahalgam-Betaab
                                      Valley-Chandanwari-Aru Valley
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                      After breakfast, head towards Pahalgam
                                      City via cab. Check into your hotel at
                                      Pahalgam and relax. One can visit Betaab
                                      Valley. Betaab valley is a very famous
                                      tourist spot situated at a distance of 15
                                      Kilometres from Pahalgam. The valley got
                                      its name from the sunny Deol- Amrita Singh
                                      hit debut film Betaab. The valley is
                                      towards the northeast of Pahalgam and
                                      falls between Pahalgam and Chandanwari and
                                      en route Amarnath Temple Yatra. The Valley
                                      is surrounded by lush green meadows, snow
                                      clad mountains and covered with dense
                                      vegetation. (Direct payment basis).
                                      Chandanwari is situated 16kms from
                                      Pahalgam, Chandanwari is the starting
                                      point of the Amarnath Yatra, which takes
                                      place in the month of Sawan (Rain). It is
                                      famous for snow sledding on a snow bridge.
                                      (Direct payment basis). Aru Valley, Aru is
                                      located about 12 kms from Pahalgam, 11 kms
                                      upstream from Lidder River. Noted for its
                                      scenic meadows, lakes, and mountains, it
                                      is a base camp for trekking to the Kolahoi
                                      Glacier and Tarsar Lake. The village lies
                                      on the left bank of the Aru River, which
                                      is a tributary of the Lidder River.
                                      (Direct payment basis). In the evening
                                      return back to the hotel. The evening is
                                      for leisure. Enjoy a delicious dinner &
                                      have an overnight stay in the hotel.
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>05</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      Day 5 : Pahalgam(The valley of
                                      Shepherds)-Srinagar(95kms one way)-Stay in
                                      a Houseboat
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                      After breakfast, Check-out from the hotel
                                      and get ready for a drive towards Srinagar
                                      (2730Mtrs above from sea level). On
                                      arrival, check into the Houseboat. Freshen
                                      up and relax, later get ready for a
                                      mesmerizing Shikara ride in the famous Dal
                                      Lake. Gaze at the beautiful lake and enjoy
                                      a mesmerizing sunset. Evening is free for
                                      leisure; you can chill and relax in the
                                      houseboat. Enjoy a hearty dinner and
                                      overnight stay in the houseboat. Pahalgam
                                      was once a humble shepherd’s village with
                                      breath-taking views. Now it is Kashmir’s
                                      premier resort, cool even during the
                                      height of summer when the maximum
                                      temperature does not exceed 25o C. Tulip
                                      Garden in Kashmir is Asia’s largest tulips
                                      garden and there is a likely hood that it
                                      can become the world’s second largest too.
                                      The tulip garden which is in Srinagar is
                                      commonly known as Siraj Bagh and is
                                      located on the banks of the famous Dal
                                      Lake. When viewing the garden you are able
                                      to see the large variety of colours of the
                                      flowers, and there are over 60 varieties
                                      of early, mid and late blooming tulips.
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>06</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>Day 6 : Depature</h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                      After breakfast leave Srinagar Houseboat
                                      and get transferred to Srinagar Airport.
                                      Your memorable journey to Kashmir ends
                                      here.
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div
                        className="tab-pane fade"
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        <div className="tab-contant-3">
                          <div class="row">
                            <div class="col-sm-12 col-md-6 mb-3">
                            <img src={gallery1Img} alt="" class="fluid img-thumbnail" />      
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3">
                              <img src={gallery1Img} alt="" class="fluid img-thumbnail" />      
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3">
                              <img src={gallery1Img} alt="" class="fluid img-thumbnail" />      
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3">
                              <img src={gallery1Img} alt="" class="fluid img-thumbnail" />      
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3">
                              <img src={gallery1Img} alt="" class="fluid img-thumbnail" />      
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3">
                              <img src={gallery1Img} alt="" class="fluid img-thumbnail" />      
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
               <PackageSideBar />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PackageDetails;
