import React, { Component } from "react";
import { Link } from "react-router-dom";

import pd_thumb from "../../../assets/images/package/kashmir/k1.jpg";
import pr_1 from "../../../assets/images/package/pr-1.png";

import gallery1Img from "../../../assets/images/package/kashmir/k1.jpg";

import "react-datepicker/dist/react-datepicker.css";
import PackageSideBar from "./Package_Components/PackageSideBar";
import LeaveCooment from "./Package_Components/packageComment";

class PackageDetails extends Component {

  componentDidMount() {
    this.scrollTop();
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {

    return (
      <>
        {/* ===============  breadcrumb area start =============== */}
        <div className="breadcrumb-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="breadcrumb-wrap">
                  <h2>Tour Package Details</h2>
                  <ul className="breadcrumb-links">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                      <i className="bx bx-chevron-right" />
                    </li>
                    <li>Package Details</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  breadcrumb area end =============== */}
        <div className="package-details-wrapper pt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="package-details">
                  <div className="package-thumb">
                    <img src={pd_thumb} alt="" />
                  </div>
                  <div className="package-header">
                    <div className="package-title">
                      <h3> Goa</h3>
                      <strong>
                        <i className="flaticon-arrival" />
                       Goa
                      </strong>
                    </div>
                    <div className="pd-review">
                      <p>Excellent</p>
                      <ul>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bx-star" />
                        </li>
                      </ul>
                      <p>8 Review</p>
                    </div>
                  </div>
                  <div className="p-short-info">
                    <div className="single-info">
                      <i className="flaticon-clock" />
                      <div className="info-texts">
                        <strong>Duration</strong>
                        <p>4 Day| 3 Night</p>
                      </div>
                    </div>
                    <div className="single-info">
                      <i className="flaticon-footprints" />
                      <div className="info-texts">
                        <strong>Tour Type</strong>
                        <p>Daily Tour</p>
                      </div>
                    </div>
                    <div className="single-info">
                      <i className="flaticon-traveller" />
                      <div className="info-texts">
                        <strong>Group Size</strong>
                        <p>Unlimited</p>
                      </div>
                    </div>
                    <div className="single-info">
                      <i className="flaticon-translate" />
                      <div className="info-texts">
                        <strong>Languages</strong>
                        <p>English/Hindi</p>
                      </div>
                    </div>
                  </div>
                  <div className="package-tab">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          <i className="flaticon-info" />
                          Information
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          <i className="flaticon-clipboard" />
                          Travel Plan
                        </button>
                      </li>
                      {/* <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-contact-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-contact"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                        >
                          {" "}
                          <i className="flaticon-gallery" />
                          Our Gallary
                        </button>
                      </li> */}
                    </ul>
                    <div
                      className="tab-content p-tab-content"
                      id="pills-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="tab-content-1">
                              <div className="p-overview">
                                <h5>Overview</h5>
                                <p>
                                  Goa is one of India's most visited
                                  destinations and is very popular for its
                                  beaches, rave parties, nightlife, flea markets
                                  and casinos.
                                </p>
                              </div>
                              <div className="p-highlight">
                                <h5>Hotel Options</h5>
                                <hr></hr>
                                <b>Option 1</b>
                                <p>
                                Camelot Fantasy Resort(3 Star) / Hotel Tranquil Inn, Calangute(3 Star) 
                                / Shalom Indus Residency(3 Star)/ Similar<br></br>
                               <b> Price: </b>
                                INR 6,750/- Per Person
                                </p><hr></hr>

                                <b>Option 2</b>
                                <p>
                                Peninsula Beach Resort(3 Star) /	Hotel Amani Vagator(3 Star) / Similar<br></br>
                               <b> Price: </b>
                                INR 7,295/- Per Person

                                </p><hr></hr>

                                <b>Option 3</b>
                                <p>
                                Hotel Silver Shell(3 Star) / Lambana Resort (3 Star) / JW Heritage, 
                                Anjuna(3 Star) / The Baga Marina Resort (3 Star Premium) / Similar<br></br>
                               <b> Price: </b>
                                INR 7,795/- Per Person
                                </p><hr></hr>
                                <b>Option 4</b>
                                <p>
                                Aromiaa Villas (3star) /Sairaj Beach Resort(4 Star) /Rivasa Emerald (3 Star Premiuim) / Similar<br></br>
                               <b> Price: </b>
                                INR 8,895/- Per Person
                                </p><hr></hr>

                                <b>Option 5</b>
                                <p>
                                Regenta Central North Goa (4 Star) /Evoke Lifestyle (4 Star)/ Similar<br></br>
                               <b> Price: </b>
                                INR 9,895/- Per Person
                                </p>
                                <hr></hr>

                                <b>Option 6</b>
                                <p>Colonia Santa Maria(4 Star Beach Resort)<br></br>
                               <b> Price: </b>
                               INR 14,195/- Per Person
                                </p>
                                <hr></hr>
                              </div>
                              <div className="p-details-table">
                                <table className="table caption-top">
                                  <tbody>
                                    <tr>
                                      <td>Destination</td>
                                      <td>Goa</td>
                                    </tr>
                                    <tr>
                                      <td>Depature</td>
                                      <td>Yes Required</td>
                                    </tr>

                                    <tr>
                                      <td>Included</td>
                                      <td>
                                        <ul className="table-list-allow">
                                          <li>
                                            <i className="bx bx-check" />• 3
                                            Night Accommodation{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" /> •
                                            Breakfast and Dinner (3 Breakfast, 3
                                            Dinner)
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" /> •
                                            North Goa Sightseeing – Sharing
                                            basis
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" />• South
                                            Goa Sightseeing – Sharing Basis
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bx-check" /> • Pick
                                            up and drop from Goa Airport.
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Excluded</td>
                                      <td>
                                        <ul className="table-list-disallow">
                                          <li>
                                            {" "}
                                            <i className="bx bx-x" />
                                            5% GST.{" "}
                                          </li>
                                          <li>
                                            <i className="bx bx-x" /> Lunch{" "}
                                          </li>
                                          <li>
                                            <i className="bx bx-x" /> Water
                                            Sports or any other Activities ( Can
                                            be arranged at extra cost).{" "}
                                          </li>
                                          <li>
                                            <i className="bx bx-x" /> nything
                                            that is not mentioned in the
                                            Inclusions.
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="p-highlight">
                                <h5>Extra Activities</h5>
                                <ul>
                                <li>
                                    <i className="bx bx-circle" /> Water Activities at Malvan @ 3,150/- Per
                                  Person Includes</li>
                                  • Pick up and Drop from Hotel<br></br>
                                  • Scuba Diving + 5 Water Activities<br></br>
                                  • Lunch<br></br>
                                  <br></br>
                                  <li>
                                    <i className="bx bx-circle" />Dudhsagar Waterfall Trip @ 3,150/- Per Person
                                Includes </li>
                                <li>Hello</li>
                                • Pick up and Drop from Hotel <br></br>
                                • Jeep Safari Fees  <br></br>
                                • Forest Entry Fees  <br></br>
                                • Lunch
                                <br></br>
                                <br></br>
                                </ul>
                                <p>
                                <b>Note: </b>For these activities Pick up and drop is not possible from Hotel Amani Vagator / Hotel JW Heritage.
                                 You have to come to a suggested pick up point by yourself.
                                </p>
                  
                                
                              </div>
                              <div className="p-rationg">
                                <h5>Rating</h5>
                                <div className="rating-card">
                                  <div className="r-card-avarag">
                                    <h2>4.9</h2>
                                    <h5>Excellent</h5>
                                  </div>
                                  <div className="r-card-info">
                                    <ul>
                                      <li>
                                        <strong>Accommodation</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Transport</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Comfort</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Hospitality</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Food</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="p-review">
                                <ul>
                                  <li className="p-review-card">
                                    <div className="p-review-info">
                                      <div className="p-reviewr-img">
                                        <img src={pr_1} alt="" />
                                      </div>
                                      <div className="p-reviewer-info">
                                        <strong>Bertram Bil</strong>
                                        <p>2 April, 2021 10.00PM</p>
                                        <ul className="review-star">
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="p-review-texts ">
                                      <p>
                                        I am a little late in posting a review.
                                        We returned from this trip in early
                                        March, right when everything started to
                                        close down due to Covid-19. I can tell
                                        you that the memories of this trip have
                                        kept me going while sheltering in place.
                                        If you are thinking this might be the
                                        trip for you, please know that it is
                                        very fast paced with moving from place
                                        to place almost every night was quite
                                        challenging. The cycling itself was
                                        challenging based on ones fitness level.
                                        My husband completed every kilometer,
                                        while I did about three-quarters of the
                                        cycling. A lot of it depends on the
                                        heat, but for a female in my early 50s,
                                        I found it very doable. We had the most
                                        fabulous guide and crew. I cannot say
                                        enough of about our guide Son - he was
                                        absolutely amazing - taking care of all
                                        the details while we enjoyed ourselves.
                                        There are plenty of water/snack stops
                                        with fresh fruit and amazing snacks
                                        (ginger/nuts/cookies). They take very
                                        good care of you.
                                      </p>
                                    </div>
                                    <Link to={`#`} className="r-reply-btn">
                                      <i className="bx bx-reply" /> Reply
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                             <LeaveCooment />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <div className="tab-content-2">
                          <div className="row">
                            <div className="col-lg-12">
                              {/* <div className="p-timeline-overview">
                                                            <h5>Overview</h5>
                                                            <p>Pellentesque accumsan magna in augue sagittis, non fringilla eros molestie. Sed feugiat mi nec ex vehicula, nec vestibulum orci semper. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec tristique commodo fringilla. Duis aliquet varius mauris eget rutrum. Nullam sit amet justo consequat, bibendum orci in, convallis enim. Proin convallis neque viverra finibus cursus. Mauris lacinia lacinia erat in finibus.</p>
                                                        </div> */}
                              <ul className="p-timeline">
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>01</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      Day 1 : Arrival at Goa
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                    Arrive airport & transfer to Hotel. Check in to the hotel 
                                    (Note: Early check in will be subject to the availability of rooms).
                                    After Check in, relax, refresh and later enjoy a dip in the pool.
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>02</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      DAY 2 : North Goa Sightseeing
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                    After breakfast we proceed for Sightseeing of North Goa. 
                                    Visit Fort Agauda, Baga beach, Calangute beach, Anjuna beach,
                                     Vagator beach. Come back to hotel and overnight stay at hotel.
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>03</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      DAY 3 : South Goa Sightseeing
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                    After breakfast we proceed full day sightseeing of South Goa.
                                     We will cover Miramar beach, Dauna Paula beach, Old Goa church, 
                                     Mangeshi temple or Santadurga temple, Boat cruise point (Boat cruise ticket at own). 
                                     Return to hotel in the evening and overnight stay at Hotel.
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>04</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      DAY 4 : Departure
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                    After breakfast check out from the hotel and 
                                    drop to the airport/ Railway station for your onward journey with sweet memories of the Goa trip.
                                    </p>
                                  </div>
                                </li>
                                
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div
                        className="tab-pane fade"
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        <div className="tab-contant-3">
                        <div class="row">
                            <div class="col-sm-12 col-md-6 mb-3">
                              <Link to={gallery1Img} className='d-block mb-4 h-100'>
                            <img src={gallery1Img} alt="" class="fluid img-thumbnail" />   
                            </Link>   
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3">
                              <img src={gallery1Img} alt="" class="fluid img-thumbnail" />      
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3">
                              <img src={gallery1Img} alt="" class="fluid img-thumbnail" />      
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3">
                              <img src={gallery1Img} alt="" class="fluid img-thumbnail" />      
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3">
                              <img src={gallery1Img} alt="" class="fluid img-thumbnail" />      
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3">
                              <img src={gallery1Img} alt="" class="fluid img-thumbnail" />      
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
               <PackageSideBar />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PackageDetails;
