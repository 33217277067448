import React, { Component } from "react";
import { Link } from "react-router-dom";

import pd_thumb from "../../../assets/images/package/alleppey.jpg";
import pr_1 from "../../../assets/images/package/pr-1.png";

import gallery1Img from "../../../assets/images/package/kashmir/k1.jpg";

import "react-datepicker/dist/react-datepicker.css";
import PackageSideBar from "./Package_Components/PackageSideBar";
import LeaveCooment from "./Package_Components/packageComment";
import GalleryWrapper from "./Package_Components/galleryWraper";

class PackageDetails extends Component {
  componentDidMount() {
    this.scrollTop();
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <>
        {/* ===============  breadcrumb area start =============== */}
        <div className="breadcrumb-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="breadcrumb-wrap">
                  <h2>Tour Package Details</h2>
                  <ul className="breadcrumb-links">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                      <i className="bx bx-chevron-right" />
                    </li>
                    <li>Package Details</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  breadcrumb area end =============== */}
        <div className="package-details-wrapper pt-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="package-details">
                  <div className="package-thumb">
                    <img src={pd_thumb} alt="" />
                  </div>
                  <div className="package-header">
                    <div className="package-title">
                      <h3> Munnar, Thekkady, Alleppey </h3>
                      <strong>
                        <i className="flaticon-arrival" />
                        Kerala
                      </strong>
                    </div>
                    <div className="pd-review">
                      <p>Excellent</p>
                      <ul>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bxs-star" />
                        </li>
                        <li>
                          <i className="bx bx-star" />
                        </li>
                      </ul>
                      <p>8 Review</p>
                    </div>
                  </div>
                  <div className="p-short-info">
                    <div className="single-info">
                      <i className="flaticon-clock" />
                      <div className="info-texts">
                        <strong>Duration</strong>
                        <p>5 Day|4 Night</p>
                      </div>
                    </div>
                    <div className="single-info">
                      <i className="flaticon-footprints" />
                      <div className="info-texts">
                        <strong>Tour Type</strong>
                        <p>Daily Tour</p>
                      </div>
                    </div>
                    <div className="single-info">
                      <i className="flaticon-traveller" />
                      <div className="info-texts">
                        <strong>Group Size</strong>
                        <p>Unlimited</p>
                      </div>
                    </div>
                    <div className="single-info">
                      <i className="flaticon-translate" />
                      <div className="info-texts">
                        <strong>Languages</strong>
                        <p>English/Hindi</p>
                      </div>
                    </div>
                  </div>
                  <div className="package-tab">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          <i className="flaticon-info" />
                          Information
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          <i className="flaticon-clipboard" />
                          Travel Plan
                        </button>
                      </li>
                      {/* <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-contact-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-contact"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                        >
                          {" "}
                          <i className="flaticon-gallery" />
                          Our Gallary
                        </button>
                      </li> */}
                    </ul>
                    <div
                      className="tab-content p-tab-content"
                      id="pills-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="tab-content-1">
                              <div className="p-overview">
                                <h5>Overview</h5>
                                <p>
                                  Munnar is a town in the Western Ghats mountain
                                  range in India’s Kerala state. A hill station
                                  and former resort for the British Raj elite,
                                  it's surrounded by rolling hills dotted with
                                  tea plantations established in the late 19th
                                  century.
                                </p>
                              </div>
                              <div className="p-highlight">
                                <h5>Hotel Options</h5>
                                <hr></hr>
                                <p>
                                  <b>OPTION 1</b>
                                  <br></br>
                                  <b>Munnar: </b>
                                  Laspalms Munnar / Bella Vista Resorts /
                                  Similar
                                  <br></br>
                                  <b>Thekkady : </b>
                                  Jungle Park Resorts / Similar
                                  <br></br>
                                  <b> Alleppey: </b>
                                  Acardia Regency / Similar
                                  <br></br>
                                  <b> Price:</b>
                                  <table className="table  p-price">
                                    <tbody>
                                      <tr>
                                        <td>
                                          Number of Pax Travelling together
                                        </td>
                                        <td>2 Pax</td>
                                        <td>4 Pax</td>
                                        <td>6 Pax</td>
                                      </tr>
                                      <tr>
                                        <td>Price Per Person</td>
                                        <td>13,499</td>
                                        <td>10,499</td>
                                        <td>9,999</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <b>Note: </b>
                                  If You want to stay in Private Houseboat
                                  instead of hotel in Alleppey, then price will
                                  increase by Rs. 2750 Per Person
                                  <hr></hr>
                                  <b>OPTION 2</b>
                                  <br></br>
                                  <b>Munnar: </b>
                                  Camellia and Elettaria/ Orchid Highlands /
                                  Similar
                                  <br></br>
                                  <b>Thekkady</b>
                                  Hotel Peppervine / Similar
                                  <br></br>
                                  <b>Alleppey:</b>
                                  Oxygen Resorts / Similar
                                  <br></br>
                                  <b> Price:</b>
                                  <table className="table  p-price">
                                    <tbody>
                                      <tr>
                                        <td>
                                          Number of Pax Travelling together
                                        </td>
                                        <td>2 Pax</td>
                                        <td>4 Pax</td>
                                        <td>6 Pax</td>
                                      </tr>
                                      <tr>
                                        <td>Price Per Person</td>
                                        <td>15,599</td>
                                        <td>12,999</td>
                                        <td>12,499</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <b>Note: </b>- If You want to stay in Private
                                  Houseboat instead of hotel in Alleppey, then
                                  price will increase by Rs. 2750 Per Person
                                  <br></br>- Hotels are subjected to
                                  availability<br></br>- This Price is not valid
                                  from 15th Dec to 10th Jan
                                  <hr></hr>
                                </p>
                              </div>
                              <div className="p-details-table">
                                <table className="table caption-top">
                                  <tbody>
                                    <tr>
                                      <td>Destination</td>
                                      <td>Munnar</td>
                                    </tr>
                                    <tr>
                                      <td>Depature</td>
                                      <td>Yes Required</td>
                                    </tr>

                                    <tr>
                                      <td>Included</td>
                                      <td>
                                        <ul className="table-list-allow">
                                          <li>
                                            <i className="bx bx-check" />
                                            Accommodation in well-appointed base
                                            category rooms
                                          </li>
                                          <li>
                                            <i className="bx bx-check" />
                                            Daily breakfast in hotels
                                          </li>
                                          <li>
                                            <i className="bx bx-check" />{" "}
                                            Transportation<br></br>o Swift Dzire
                                            or similar vehicle (Upto 4 people)
                                            <br></br>o Toyoto Innova or similar
                                            vehicle (5 – 6 People)
                                          </li>
                                          <li>
                                            <i className="bx bx-check" />
                                            Driver bata and fuel charges
                                          </li>
                                          <li>
                                            <i className="bx bx-check" /> All
                                            applicable hotel taxes
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Excluded</td>
                                      <td>
                                        <ul className="table-list-disallow">
                                          <li>
                                            <i className="bx bx-x" /> GST @ 5%
                                          </li>
                                          <li>
                                            <i className="bx bx-x" />
                                            Any train fare / airfare / bus fare
                                          </li>
                                          <li>
                                            <i className="bx bx-x" /> Any
                                            entrance fees any monument
                                          </li>
                                          <li>
                                            <i className="bx bx-x" /> Lunch and
                                            Dinner at Hotels
                                          </li>
                                          <li>
                                            <i className="bx bx-x" /> Expenses
                                            occurred by caused beyond our
                                            control like rail and flights delay
                                            , cancelled, taxi strike , flood ,
                                            landslide , road blocks , political
                                            issue
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div>
                                  <h3>Important Notes:</h3>
                                  <p>
                                    • At Munnar and Thekadey ac rooms will not
                                    be provided due to Low temperature<br></br>•
                                    At hotel check inn time is 14:00 hrs and
                                    check out time is 12:00 noon<br></br>• At
                                    houseboat check in time is 12:00 hrs and
                                    check out time is 09:00 AM<br></br>• Early
                                    check inn and late check out from hotel and
                                    houseboat is subject to rooms availability
                                    <br></br>• In A/C Deluxe houseboat ac will
                                    operational timing is 9:00PM – 6:00AM
                                    <br></br>• All houseboats will be remain
                                    standstill from 5:30 PM – 8:00AM due to Govt
                                    restrictions
                                  </p>
                                  <hr></hr>
                                  <h3>Cancellation Policy</h3>
                                  <p>
                                    • Date of booking to 31 days before travel
                                    the cancellation charges will be 25% of the
                                    tour cost<br></br>• 30 to 16 days before
                                    travel - cancellation charges will be 50% of
                                    the tour cost<br></br>• 15 to 8 days before
                                    travel - cancellation charges will be 75% of
                                    the tour cost<br></br>• 0 to 7 days before
                                    travel - cancellation charges will be 100%
                                    of the tour cost. No refund shall be given
                                    <br></br>• Please Note: Cancellation policy
                                    is subject to change. It depends on the
                                    hotel policy. In peak season (example: long
                                    weekends, festival season, summer vacation
                                    etc.) most of the hotels charge 100%
                                    cancellation.<br></br>• There will also be
                                    cancellation/amendment facilitation fee of
                                    Rs 2,500 per person (for Domestic Package)
                                    and Rs 5,000 per person (for International
                                    Package)<br></br>
                                  </p>
                                </div>
                              </div>

                              <div className="p-rationg">
                                <h5>Rating</h5>
                                <div className="rating-card">
                                  <div className="r-card-avarag">
                                    <h2>4.9</h2>
                                    <h5>Excellent</h5>
                                  </div>
                                  <div className="r-card-info">
                                    <ul>
                                      <li>
                                        <strong>Accommodation</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Transport</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Comfort</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Hospitality</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <strong>Food</strong>
                                        <ul className="r-rating">
                                          <li>
                                            <i className="bx bxs-star" />
                                            <i className="bx bxs-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                            <i className="bx bx-star" />
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="p-review">
                                <ul>
                                  <li className="p-review-card">
                                    <div className="p-review-info">
                                      <div className="p-reviewr-img">
                                        <img src={pr_1} alt="" />
                                      </div>
                                      <div className="p-reviewer-info">
                                        <strong>Bertram Bil</strong>
                                        <p>2 April, 2021 10.00PM</p>
                                        <ul className="review-star">
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                          <li>
                                            {" "}
                                            <i className="bx bxs-star" />{" "}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="p-review-texts ">
                                      <p>
                                        I am a little late in posting a review.
                                        We returned from this trip in early
                                        March, right when everything started to
                                        close down due to Covid-19. I can tell
                                        you that the memories of this trip have
                                        kept me going while sheltering in place.
                                        If you are thinking this might be the
                                        trip for you, please know that it is
                                        very fast paced with moving from place
                                        to place almost every night was quite
                                        challenging.{" "}
                                      </p>
                                    </div>
                                    <Link to={`#`} className="r-reply-btn">
                                      <i className="bx bx-reply" /> Reply
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <LeaveCooment />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <div className="tab-content-2">
                          <div className="row">
                            <div className="col-lg-12">
                              {/* <div className="p-timeline-overview">
                                                            <h5>Overview</h5>
                                                            <p>Pellentesque accumsan magna in augue sagittis, non fringilla eros molestie. Sed feugiat mi nec ex vehicula, nec vestibulum orci semper. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec tristique commodo fringilla. Duis aliquet varius mauris eget rutrum. Nullam sit amet justo consequat, bibendum orci in, convallis enim. Proin convallis neque viverra finibus cursus. Mauris lacinia lacinia erat in finibus.</p>
                                                        </div> */}
                              <ul className="p-timeline">
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>01</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      Day 1 : Cochin to Munnar(135km/5.5hrs)
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                      • Starts from Cochin and reaches Munnar by
                                      afternoon<br></br>• On the way visit
                                      Cheyyara waterfalls, Valara waterfalls,
                                      Spice plantations etc<br></br>• Relax and
                                      stay at Munnar
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>02</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>DAY 2 : Munnar(60 to 80km)</h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                      • Sightseeing destinations includes
                                      eravikulam national park (Raja Malai),
                                      mattupetty dam, tea estates, eco point,
                                      spice plantation, flower garden, photo
                                      point, honey bee tree, tea museum, hydel
                                      park, kundale dam and top station
                                      <br></br>• Overnight stay at Munnar (Raja
                                      Malai will be closed from February to
                                      April mid due to breeding season of Goats)
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>03</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      DAY 3 : Munnar to Thekkady(105km/3.5hrs)
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                      • Sightseeing destinations include Periyar
                                      wild life sanctuary where you can see wild
                                      elephant, boar, deer, the great Indian
                                      tiger and more, mullaperiyar dam (you can
                                      watch all these only while doing boating
                                      in the Lake)
                                      <br></br>• You can watch martial arts,
                                      kathakali, elephant ride, elephant bath,
                                      tribal dance, musical fountain and can go
                                      for ayurveda massages (all are optional
                                      activities with separate entrance fees)
                                      <br></br>• Overnight stay at Thekkady
                                      (During rush time, getting ticket for
                                      periyar boating is difficult, you may even
                                      book boat ticket in advance through KTDC
                                      Lake Palace - 04869 222014 or 09400008589
                                      or online)
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>04</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      DAY 4 :Thekkady to Alleppey
                                      Houseboat(150km/3.5hrs)
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                      • Drive to Alleppey in the morning
                                      <br></br>• Check in to houseboat and enjoy
                                      the backwaters, scenery and different
                                      places<br></br>• Overnight stay in the
                                      houseboat<br></br>
                                      Or<br></br>
                                      (if Houseboat is not chosen)<br></br>•
                                      After breakfast drive to Alleppey upon
                                      arrival check in your hotel, later city
                                      tour for of Alleppey. Overnight at hotel
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="timeline-index">
                                    <div className="index-circle">
                                      <h5>05</h5>
                                    </div>
                                  </div>
                                  <div className="timeline-content">
                                    <h5>
                                      DAY 5 : Alleppey to Cochin Drop (55 Kms)
                                    </h5>
                                    {/* <strong>10.00 AM to 10.00 PM</strong> */}
                                    <p>
                                      • Cochin sightseeing includes fort cochin,
                                      navy museum, St Francis church, Chinese
                                      fishing net, Santa clause basilica,
                                      Mattanchery palace, Jewish synagogue,
                                      Marian drive, Marine walk way, boating,
                                      Tripunitura hill palace<br></br>• You can
                                      find some time for shopping at Cochin
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        {/* <div className="tab-contant-3">
                          <GalleryWrapper />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <PackageSideBar />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PackageDetails;
