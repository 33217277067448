import React, { Component } from "react";
import {Link} from "react-router-dom";

import ReactOwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
//Load Image
import package1Img from "../../../assets/images/package/km1.png"
import package2Img from "../../../assets/images/package/kashmir/p-k1.png"
import package3Img from "../../../assets/images/package/p-3.png"
import packageGoaImg from "../../../assets/images/package/goa1.jpg"
import package5Img from "../../../assets/images/package/p-5.png"
import package6Img from "../../../assets/images/package/p-6.png"
import package7Img from "../../../assets/images/package/p-7.png"
import package8Img from "../../../assets/images/package/p-8.png"
import package9Img from "../../../assets/images/package/p-9.png"
import package10Img from "../../../assets/images/package/p-10.png"
import package11Img from "../../../assets/images/package/p-11.png"
import { PackageInfo } from "../package/packagesInfo";





class ChoosePackages extends Component {


  render() {
    const options = {
      items: 1,
          loop: true,
          margin:0,
          smartSpeed: 4000,
          // dots: true,
          // nav: true,
          autoplay: 4000,
          autoplayTimeout:4000,
          autoplayHoverPause:true,
          animateIn: 'fadeIn',
          animateOut: 'fadeOut',
          // navText : ["<i class='bx bx-chevron-left ' ></i>","<i class='bx bx-chevron-right'></i>"],
          responsive:{
              0:{
                  items:1,
                  nav:false,
                  dots : true
              },
              5000:{
                  items:1,
                  nav:false,
                  dost : true,
              }
              // 1000:{
              //     items:1,
              //     nav:true,
              //     loop:true
              // },
          }      
    };
    return (
       <>
         {/* ===============  Package  area start =============== */}
         <div className="package-area pt-120">
           <div className="container">

             <div className="row">
               <div className="col-lg-12 col-md-12 col-sm-12">
                 <div className="section-head pb-45">
                   <h5>Choose Your Package</h5>
                   <h2>Select Your best Package For Your Travel</h2>
                 </div>
               </div>
             </div>
            <ReactOwlCarousel className="owl-carousel" {...options}>
             <div  className="row">   
             
                  
                 <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="300ms">
                 <div className="package-card ">
                 
                   <div className="package-thumb">
                     <Link to={`${process.env.PUBLIC_URL}/package-details`}>
                       <img src={package1Img} alt="" className="img-fluid" />
                     </Link>
                   </div>
                   <div className="package-details">
                     <div className="package-info">
                       <h5><span>₹8,000</span>/Per Person</h5>
                       <h5><i className="flaticon-calendar" />5 Days|4 night</h5>
                     </div>
                     <h3>
                       <i className="flaticon-arrival" />
                       <Link to={`${process.env.PUBLIC_URL}/package-details`}>Kullu-Manali</Link>
                     </h3>
                     <br></br>
                     <div className="package-rating">
                       <strong><i className="bx bxs-star" /><span>8K+</span> Rating</strong>
                     </div>
                   </div>
                 </div>
               </div>
               <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="300ms">
                 <div className="package-card">
                   <div className="package-thumb">
                     <Link to={`${process.env.PUBLIC_URL}/kashmir`}>
                       <img src={package2Img} alt="" className="img-fluid" />
                     </Link>
                   </div>
                   <div className="package-details">
                     <div className="package-info">
                       <h5><span>₹20,000</span>/Per Person</h5>
                       <h5><i className="flaticon-calendar" />6 Days|5 night</h5>
                     </div>
                     <h3>
                       <i className="flaticon-arrival" />
                       <Link to={`${process.env.PUBLIC_URL}/kashmir`}>Srinagar-Gulmarg<br></br></Link>
                     </h3>
                     <br></br>
                     <div className="package-rating">
                       <strong><i className="bx bxs-star" /><span>8K+</span> Rating</strong>
                     </div>
                   </div>
                 </div>
               </div>

               <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="0ms">
                 <div className="package-card">
                   <div className="package-thumb">
                     <Link to={`${process.env.PUBLIC_URL}/goa`}>
                       <img src={packageGoaImg} alt="" className="img-fluid" />
                     </Link>
                   </div>
                   <div className="package-details">
                     <div className="package-info">
                       <h5><span>₹6,790</span>/Per Person</h5>
                       <h5><i className="flaticon-calendar" />4 Days/3 night</h5>
                     </div>
                     <h3>
                       <i className="flaticon-arrival" />
                       <Link to={`${process.env.PUBLIC_URL}/goa`}>Goa<br></br></Link>
                     </h3>
                     <br></br>
                     <div className="package-rating">
                       <strong><i className="bx bxs-star" /><span>1K+</span> Rating</strong>
                     </div>
                   </div>
                 </div>
               </div>

               <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="300ms">
                 <div className="package-card">
                   <div className="package-thumb">
                     <Link to={`${process.env.PUBLIC_URL}/coorg`}>
                       <img src={package5Img} alt="" className="img-fluid" />
                     </Link>
                   </div>
                   <div className="package-details">
                     <div className="package-info">
                       <h5><span>₹11,495</span>/Per Person</h5>
                       <h5><i className="flaticon-calendar" />3 Days/2 night</h5>
                     </div>
                     <h3>
                       <i className="flaticon-arrival" />
                       <Link to={`${process.env.PUBLIC_URL}/coorg`}>Coorg,Karnataka<br></br></Link>
                     </h3>
                     <br></br>
                     <div className="package-rating">
                       <strong><i className="bx bxs-star" /><span>1K+</span> Rating</strong>
                     </div>
                   </div>
                 </div>
               </div>

               <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="600ms">
                 <div className="package-card">
                   <div className="package-thumb">
                     <Link to={`${process.env.PUBLIC_URL}/ooty`}>
                       <img src={package6Img} alt="" className="img-fluid" />
                     </Link>
                   </div>
                   <div className="package-details">
                     <div className="package-info">
                       <h5><span>₹15,995</span>/Per Person</h5>
                       <h5><i className="flaticon-calendar" />4 Days|3 night</h5>
                     </div>
                     <h3>
                       <i className="flaticon-arrival" />
                       <Link to={`${process.env.PUBLIC_URL}/ooty`}>Mysore-Ooty-Coonor <br></br></Link>
                     </h3>
                     <br></br>
                     <div className="package-rating">
                       <strong><i className="bx bxs-star" /><span>1K+</span> Rating</strong>
                     </div>
                   </div>
                   
                 </div>
                 
               </div>
               <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="600ms">
                 <div className="package-card">
                   <div className="package-thumb">
                     <Link to={`${process.env.PUBLIC_URL}/kerala1`}>
                       <img src={package7Img} alt="" className="img-fluid" />
                     </Link>
                   </div>
                   <div className="package-details">
                     <div className="package-info">
                       <h5><span>₹10,999</span>/Per Person</h5>
                       <h5><i className="flaticon-calendar" />4 Days|3 night</h5>
                     </div>
                     <h3>
                       <i className="flaticon-arrival" />
                       <Link to={`${process.env.PUBLIC_URL}/kerala1`}>Munnar-Thekkady<br></br> </Link>
                     </h3>
                     <br></br>
                     <div className="package-rating">
                       <strong><i className="bx bxs-star" /><span>8K+</span> Rating</strong>
                     </div>
                   </div>
                   
                 </div>
                 
               </div>
             </div>
             <div  className="row">   
            
                  
                 <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="300ms">
                 <div className="package-card ">
                 
                   <div className="package-thumb">
                     <Link to={`${process.env.PUBLIC_URL}/kerala2`}>
                       <img src={package8Img} alt="" className="img-fluid" />
                     </Link>
                   </div>
                   <div className="package-details">
                     <div className="package-info">
                       <h5><span>₹13,499</span>/Per Person</h5>
                       <h5><i className="flaticon-calendar" />5 Days|4 night</h5>
                     </div>
                     <h3>
                       <i className="flaticon-arrival" />
                       <Link to={`${process.env.PUBLIC_URL}/kerala2`}>Munnar-Thekkady-Alleppey</Link>
                     </h3>
                     <div className="package-rating">
                       <strong><i className="bx bxs-star" /><span>8K+</span> Rating</strong>
                     </div>
                   </div>
                 </div>
               </div>
               <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="300ms">
                 <div className="package-card">
                   <div className="package-thumb">
                     <Link to={`${process.env.PUBLIC_URL}/kerala3`}>
                       <img src={package9Img} alt="" className="img-fluid" />
                     </Link>
                   </div>
                   <div className="package-details">
                     <div className="package-info">
                       <h5><span>₹15,999</span>/Per Person</h5>
                       <h5><i className="flaticon-calendar" />6 Days|5 night</h5>
                     </div>
                     <h3>
                       <i className="flaticon-arrival" />
                       <Link to={`${process.env.PUBLIC_URL}/kerala3`}>Athirapally-Munnar-Alleppey</Link>
                     </h3>
                     <div className="package-rating">
                       <strong><i className="bx bxs-star" /><span>2K+</span> Rating</strong>
                     </div>
                   </div>
                 </div>
               </div>

               <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="600ms">
                 <div className="package-card">
                   <div className="package-thumb">
                     <Link to={`${process.env.PUBLIC_URL}/kerala4`}>
                       <img src={package10Img} alt="" className="img-fluid" />
                     </Link>
                   </div>
                   <div className="package-details">
                     <div className="package-info">
                       <h5><span>₹20,995</span>/Per Person</h5>
                       <h5><i className="flaticon-calendar" />7 Days/6 night</h5>
                     </div>
                     <h3>
                       <i className="flaticon-arrival" />
                       <Link to={`${process.env.PUBLIC_URL}/kerala4`}>Munnar-Kovalam </Link>
                     </h3>
                     <h3>

                     </h3>
                     <div className="package-rating">
                       <strong><i className="bx bxs-star" /><span>8K+</span> Rating</strong>
                     </div>
                   </div>
                 </div>
               </div>

               

               <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated" data-wow-duration="1500ms" data-wow-delay="600ms">
                 <div className="package-card">
                   <div className="package-thumb">
                     <Link to={`${process.env.PUBLIC_URL}/#`}>
                       <img src={package11Img} alt="" className="img-fluid" />
                     </Link>
                   </div>
                   <div className="package-details">
                     <div className="package-info">
                       <h5><span>₹8000</span>/Per Person</h5>
                       <h5><i className="flaticon-calendar" />4 Days/3 night</h5>
                     </div>
                     <h3>
                       <i className="flaticon-arrival" />
                       <Link to={`${process.env.PUBLIC_URL}/#`}>Udaipur </Link>
                     </h3>
                     <br></br>
                     <div className="package-rating">
                       <strong><i className="bx bxs-star" /><span>8K+</span> Rating</strong>
                     </div>
                   </div>
                   
                 </div>
                 
               </div>
           
             </div>
             </ReactOwlCarousel>     
             

           </div>
         </div>
         {/* ===============  Package  area end =============== */}
       </>
    );
  }
}

export default ChoosePackages;
